'use client'

export default function GlobalError() {
  return (
    <html lang="en">
      <body>
        <h1>Ooops!</h1>
        <p>Something went wrong while loading the page you wanted =/</p>
      </body>
    </html>
  )
}
